import React from "react"
import * as cn from "classnames"
import {graphql, Link, StaticQuery} from "gatsby"
import Slider from "react-slick/lib"
import Img from "gatsby-image"
import {Tools} from "../tools";

export default class Partners extends React.Component {
    state = {
        settings: {}
    }

    constructor(props) {
        super(props)

        if(Tools.isTablet()) {
            this.state.settings = {
                dots: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                pauseOnHover: false,
            }
        }
        else if(Tools.isMobile()) {
            this.state.settings = {
                dots: false,
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
                pauseOnHover: false,
            }
        }
        else {
            this.state.settings = {
                dots: false,
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                autoplay: true,
                pauseOnHover: false,
            }
        }
    }

    render() {
        const s = this.state

        return (
            <StaticQuery
                query={graphql`
                    query PartnersQuery {
                        allDatoCmsPartner {
                            edges {
                                node {
                                    image {
                                        fluid(imgixParams: { auto: "compress" }) {
                                            ...GatsbyDatoCmsSizes
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <Slider {...s.settings}>
                        {data.allDatoCmsPartner.edges.map(({ node: item, idx }) => (
                            <div key={idx}>
                                <Img fluid={item.image.fluid} />
                            </div>
                        ))}
                    </Slider>
                )}
            />

        )
    }
}
