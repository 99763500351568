import React from "react"
import {Tools} from "../tools"
import * as cn from "classnames"
import {graphql, Link, StaticQuery} from "gatsby"
import Slider from "react-slick/lib"
import Img from "gatsby-image"

export default class Banners extends React.Component {
    render() {
        const s = this.state

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            autoplay: true,
            autoplaySpeed: 5000
        }

        return (
            <StaticQuery
                query={graphql`
                    query BannerQuery {
                        allDatoCmsBanner {
                            edges {
                                node {
                                    image {
                                        fluid(imgixParams: { auto: "compress" }) {
                                            ...GatsbyDatoCmsSizes
                                        }
                                    }
                                    imageMobile {
                                        fluid(imgixParams: { auto: "compress" }) {
                                            ...GatsbyDatoCmsSizes
                                        }
                                    }
                                    title
                                    description
                                    link
                                    white
                                    darkOverlay
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <div className="banners">
                        <Slider {...settings}>
                            {data.allDatoCmsBanner.edges.map(({ node: item, idx }) => (
                                <div key={idx}>
                                    {Tools.isPhone() && <Img fluid={item.imageMobile.fluid} />}
                                    {!Tools.isPhone() && <Img fluid={item.image.fluid} />}
                                    {!Tools.isPhone() && item.darkOverlay && <div className="overlay"></div> }
                                    <div className={cn('content', item.white && "white")}>
                                        <h1>{item.title}</h1>
                                        <p>{item.description}</p>
                                        {item.link &&
                                            <Link to={item.link} className={cn('btn', !item.white && "btn-yellow")}>
                                                <span>Więcej</span>
                                                <i className="icon-arrow-right"></i>
                                            </Link>
                                        }
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                )}
            />

        )
    }
}
