import React from "react"
import Layout from "../components/layout"
import Banners from "../components/layout/Banners"
import Partners from "../components/layout/Partners"
import Posts from "../components/layout/Posts"
import Payu from "../components/layout/Payu"
import {graphql, StaticQuery} from "gatsby"
import {HelmetDatoCms} from "gatsby-source-datocms"

export default class Index extends React.Component {
    state = {
        loading: true
    }

    componentDidMount() {
        this.setState({loading: false})
    }

    render() {
        const s = this.state

        return (
            <StaticQuery
                query={graphql`
                    query HomeQuery {
                        datoCmsHomePage {
                         seoMetaTags {
                            ...GatsbyDatoCmsSeoMetaTags
                          }
                          mottoTitle
                          mottoDescriptionNode {
                            childMarkdownRemark{
                                html
                            }
                          }
                          wspomozNasTitle
                          wspomozNasDescriptionNode {
                            childMarkdownRemark{
                                html
                            }
                          }
                          showSection
                          sectionTitle
                          sectionDescriptionNode {
                            childMarkdownRemark{
                                html
                            }
                          }
                          sectionLink
                          sectionLinkLabel
                        }
                    }
                `}
                render={data => (
                    <Layout className="home">
                        <HelmetDatoCms seo={data.datoCmsHomePage.seoMetaTags} />
                        {!s.loading && <Banners />}
                        <div className="motto content">
                            <img src="sempre-sm.png" alt=""/>
                            <div className="motto__content">
                                <h2>{data.datoCmsHomePage.mottoTitle}</h2>
                                <pre dangerouslySetInnerHTML={{
                                    __html: data.datoCmsHomePage.mottoDescriptionNode.childMarkdownRemark.html,
                                }}
                                />
                            </div>
                        </div>
                        <div className="content">
                            {data.datoCmsHomePage.showSection &&
                                <div className="percent">
                                    <h2>{data.datoCmsHomePage.sectionTitle}</h2>
                                    <pre>{data.datoCmsHomePage.sectionDescriptionNode.childMarkdownRemark.html}</pre>
                                    <a href={data.datoCmsHomePage.sectionLink} className="btn-yellow" title={data.datoCmsHomePage.sectionLinkLabel}>
                                        <i className="icon-heart"></i>
                                        <span>{data.datoCmsHomePage.sectionLinkLabel}</span>
                                    </a>
                                </div>
                            }
                            <Posts />
                        </div>
                        <Payu />
                        <div className="partners">
                            <div className="content">
                                <h2>Kluczowi partnerzy</h2>
                                {!s.loading && <Partners />}
                            </div>
                        </div>
                    </Layout>
                )}
            />
        )
    }
}